import { graphql, useStaticQuery } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import * as React from "react";
import { useMediaQuery } from "react-responsive";
import Aside from "../../components/Aside";
import Page from "../../components/Page";
import * as P from "../../components/Page/styles";
import SubAreas from "../../components/SubAreas";
import Seo from "../../components/seo";

const RetirementByTimeContribution = () => {
  const { background } = useStaticQuery(
    graphql`
      query {
        background: file(relativePath: { eq: "banner.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1920
              quality: 100
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  );

  const image = getImage(background);
  const isDesktop = useMediaQuery({ minWidth: 768 });

  return (
    <React.Fragment>
      <Page
        title="Aposentadoria por Tempo de Contribuição"
        image={image}
        breadcrumb={[
          { page: "/", name: "Início" },
          {
            page: "/aposentadoria-e-auxilios",
            name: "Aposentadoria e Auxílios",
          },
          {
            page: "/aposentadoria-por-tempo-contribuicao",
            name: "Aposentadoria por Tempo de Contribuição",
          },
        ]}
      >
        {isDesktop && <Aside />}

        <P.Article>
          <p>
            Foi extinta na Reforma da Previdência em 2019. Todavia, isso não
            significa que você não possa se aposentar por tempo de contribuição,
            caso você tenha completado o período necessário antes de aprovada a
            lei, ou ainda é possível se adequar em uma das regras de transição
            vigentes.
          </p>
          <p>
            É muito importante que você saiba sobre isso, caso contrário pode
            perder muito dinheiro se aposentando antes do que deveria, desta
            forma, faz-se essencial que aquele que vai se aposentar por tempo de
            contribuição busque, antes do pedido, um planejamento
            previdenciário.
          </p>
          <p>
            Não obstante, você corre o risco de continuar contribuindo para o
            INSS sem necessidade e sem nenhum impacto positivo na sua
            aposentadoria ou ainda, requerer sua aposentadoria sem os documentos
            certos, perder tempo de contribuição que já é seu e deixar de se
            aposentar nesta modalidade de aposentadoria com{" "}
            <b>direito adquirido</b>.
          </p>
          <p>Sendo assim, vamos aos requisitos necessários:</p>
          <p>
            Lembre-se que, estas regras só valem para aqueles que possuem o
            direito adquirido
          </p>
          <p>
            Desta forma, o <b>tempo de contribuição</b> necessário para este
            benefício é de 35 anos para os homens e 30 anos para as mulheres.
          </p>
          <p>
            Destaca-se que a má análise, pode resultar na incidência do fator
            previdenciário, o que pode acarretar uma redução expressiva do valor
            de sua aposentadoria, por isso a necessidade de cautela e de um
            planejamento.
          </p>
          <p>
            Com a reforma, quem estava próximo de se aposentar apenas terá que
            concluir alguns requisitos do novo ordenamento, devendo cumprir
            alguns pedágios. Com as novas regras é ainda mais importante que seu
            pedido de aposentadoria seja acompanhado por um advogado
            especialista, pois na reforma, existem pedidos que incidem o fator
            previdenciário, e alguns outros que não incidem, logo, uma escolha
            errada e seu benefício pode ser reduzido de forma drástica.
          </p>
        </P.Article>

        {!isDesktop && <Aside withContact />}
      </Page>

      <SubAreas />
    </React.Fragment>
  );
};

export const Head = () => (
  <Seo
    title="Aposentadoria por Tempo de Contribuição"
    description="Orientação jurídica em Aposentadoria por Tempo de Contribuição. Ajudamos a garantir seus direitos e a entender o processo de aposentadoria, com suporte confiável e personalizado."
  />
);

export default RetirementByTimeContribution;
